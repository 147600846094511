import React from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
// import theme from '../../theme';
import { ContentWrapper, SubHeader } from '../Layout';
import * as strings from '../../strings';
import { DocViewer, DocPreview } from '../DocViewers';
import { Button } from '../Button';
import { ReactComponent as DownloadIcon } from '~/icons/download.svg';
import theme from '~/theme';

const ButtonWrapper = styled.div`
  margin: -40px auto 20px;
  width: 200px;
`;

const ButtonLabel = styled.span`
  margin-right: 20px;
`;

const ErrorMessage = styled.div`
  color: ${theme.color.status.error.medium};
  text-align: center;
`;

type CurriculumVitaeProps = {} & RouteComponentProps;

const CurriculumVitae: React.FC<CurriculumVitaeProps> = () => {
  const [isDownloading, setDownloading] = React.useState(false);
  const [err, setError] = React.useState('');
  const fileName = `${strings.en.title}.pdf`;

  // Viewer displays in a document viewer
  const viewerSource =
    'https://docs.google.com/document/d/1Hw4KC-8ziuAvGA2dGHIWx_j_cgkGuK56ZyPtMIHHsBg/export?format=pdf';

  // Used for downloading PDF of file
  const downloadUrl =
    'https://docs.google.com/document/d/1Hw4KC-8ziuAvGA2dGHIWx_j_cgkGuK56ZyPtMIHHsBg/export?format=pdf';

  // Preview loads the file as HTML
  const previewSource =
    'https://docs.google.com/document/d/e/2PACX-1vTRCsn72K7rwuDpvmmYf3C0NmMTfeoxSZ4GmiU1SGkaaNIvW9-DPxFzLVbZv0dVfvQgMA4a7Zw75_Do/pub?embedded=true';

  const downloadURI = async (): Promise<void> => {
    setError('');
    setDownloading(true);
    const response = await fetch(downloadUrl);
    if (response && response.ok) {
      const data = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(data);
      link.setAttribute('download', fileName);
      link.setAttribute('target', '_blank');
      link.click();
    } else {
      setError(strings.en.fileDownloadErr);
    }
    setDownloading(false);
  };

  const iconStyles = css`
    width: 14px;
    fill: ${theme.color.tertiary};
    position: absolute;
    right: 20%;
    top: 30%;

    @media (max-width: 575px) {
      top: 25%;
    }
  `;

  const buttonStyles = css`
    width: 100%;
  `;

  return (
    <ContentWrapper>
      <SubHeader title={strings.en.curriculumVitae} />
      <ButtonWrapper>
        <Button
          onClick={downloadURI}
          name="Download"
          size="small"
          color="primary"
          isLoading={isDownloading}
          disabled={isDownloading}
          css={buttonStyles}
        >
          <ButtonLabel>{strings.en.download}</ButtonLabel>
          <DownloadIcon css={iconStyles} />
        </Button>
        {err && <ErrorMessage>{err}</ErrorMessage>}
      </ButtonWrapper>
      <DocPreview source={previewSource} />
      {/* <DocViewer source={viewerSource} /> */}
    </ContentWrapper>
  );
};

export default CurriculumVitae;
