import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { RouteComponentProps } from '@reach/router';
import styled from '@emotion/styled';
import theme from '../../theme';
import { ContentWrapper, SubHeader } from '../Layout';
import * as strings from '../../strings';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 411px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }
`;

const HeroImage = styled(Img)`
  display: flex;
  min-width: 20rem;
  border-radius: 6px;
  box-shadow: 3px 3px 20px 0px ${theme.color.gray.medium};
  margin: 0 50px 30px 0;
  max-height: 450px;

  @media (max-width: 768px) {
    min-width: 15rem;
    margin: 30px 0 30px 0;
  }

  @media (max-width: 411px) {
    min-width: 10rem;
  }
`;

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    width: unset;
  }
`;

const ContactRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0 0;
`;

const ContactLabel = styled.span`
  width: 60px;
`;

const ContactItem = styled.a``;

type ContactProps = {} & RouteComponentProps;

const Contact: React.FC<ContactProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "window-1" }, extension: { eq: "png" }) {
        childImageSharp {
          fluid(maxWidth: 300, pngQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const title = `${strings.en.contact} ${strings.en.firstName}`;

  return (
    <ContentWrapper>
      <SubHeader title={title} />
      <SectionWrapper>
        <HeroImage
          fluid={data.file.childImageSharp.fluid}
          alt="Flower Window"
        />
        <ContactWrapper>
          <ContactRow>
            <ContactLabel>{strings.en.contactText.email}</ContactLabel>
            <ContactItem
              href="#mailgo"
              data-address="mradnia"
              data-domain="ucla.edu"
            >
              mradnia [@] ucla . edu
            </ContactItem>
          </ContactRow>
          <ContactRow>
            <ContactLabel>{strings.en.contactText.phone}</ContactLabel>
            <ContactItem href="#mailgo" data-tel="8583531766">
              (858) 353-1766
            </ContactItem>
          </ContactRow>
        </ContactWrapper>
      </SectionWrapper>
    </ContentWrapper>
  );
};

export default Contact;
