import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { RouteComponentProps } from '@reach/router';
import styled from '@emotion/styled';
import theme from '../../theme';
import { ContentWrapper, SubHeader, Paragraph } from '../Layout';
import * as strings from '../../strings';

const ResearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeroImg = styled(Img)`
  display: flex;
  border-radius: 6px;
  box-shadow: 3px 3px 20px 0px ${theme.color.gray.medium};
  margin: 0 auto 50px;
  width: 600px;

  @media (max-width: 768px) {
    width: 400px;
    margin: 0 auto 30px;
  }

  @media (max-width: 411px) {
    width: 300px;
  }

  @media (max-width: 320px) {
    width: 260px;
  }
`;

type ResearchProps = {} & RouteComponentProps;

const Research: React.FC<ResearchProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "botany" }, extension: { eq: "png" }) {
        childImageSharp {
          fluid(maxWidth: 600, pngQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <ContentWrapper>
      <SubHeader title={strings.en.research} />
      <HeroImg fluid={data.file.childImageSharp.fluid} alt="Botany" />
      <ResearchWrapper>
        {strings.en.researchText.map(txt => {
          return <Paragraph text={txt} />;
        })}
      </ResearchWrapper>
    </ContentWrapper>
  );
};

export default Research;
