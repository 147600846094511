import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import theme from '../../theme';
import { LoadingSpinner } from '~/components/LoadingIndicators';
import { fadeIn } from '../../utils/styles/animations';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px auto;
  max-width: 640px;
`;

const DocContainer = styled.div<{ isLoading?: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  background: transparent;
  opacity: 1;
  animation-duration: 0.75s;
  animation-name: ${fadeIn};
  margin: 20px 20px;
  ${p => {
    if (p.isLoading) {
      return `
        align-items: center;
        justify-content: center;
        min-height: 500px;
      `;
    }
    return '';
  }}
`;

const loadingStyles = css`
  color: ${theme.color.tertiary};
  margin; auto;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 50px;
`;

function sanitizeHTML(html: string): string {
  const regex = /\}([^\{]+?)\{/g;
  const replacer = (m: any, p1: any) => `}.js-google-docs-wrapper ${p1}{`;

  return html.replace(regex, replacer);
}

interface PreviewProps {
  source: string;
}

const DocPreview: React.FC<PreviewProps> = props => {
  const { source } = props;
  const [data, setData] = React.useState('');

  React.useEffect(() => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', source, true);
    xhr.onload = () => {
      setData(xhr.responseText);
      // setTimeout(() => setData(xhr.responseText), 2000);
    };
    xhr.send();
  }, []);

  return (
    <Wrapper>
      {!data && (
        <DocContainer isLoading className="js-google-docs-wrapper">
          <LoadingSpinner size="50" css={loadingStyles} />
        </DocContainer>
      )}
      {data && (
        <DocContainer
          className="js-google-docs-wrapper"
          dangerouslySetInnerHTML={{ __html: `${sanitizeHTML(String(data))}` }}
        />
      )}
    </Wrapper>
  );
};

export default DocPreview;
