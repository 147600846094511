import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import theme from '../../theme';
import * as strings from '../../strings';
import { ReactComponent as MIcon } from '~/icons/mr-wreath.svg';
// import { ReactComponent as MIcon } from '~/icons/mr-logo.svg';

const iconStyles = css`
  width: 40px;
  display: none;

  @media (max-width: 768px) {
    // width: 60px;
    width: 100px;
    display: flex;
  }
`;

const LogoWrap = styled(Link)`
  margin: auto 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  display: flex;
  font-family: 'Roboto', serif;
  font-weight: 300;
  font-size: 20px;
  text-transform: none;
  // margin: 5px 0 0;
  color: ${theme.color.text.heading};

  @media (max-width: 768px) {
    display: none;
  }
`;

type LogoProps = {
  isOpen: boolean;
  setMenuState: (state: boolean) => void;
};

const Logo: React.FC<LogoProps> = props => {
  const { isOpen, setMenuState } = props;

  return (
    <LogoWrap
      to="/"
      onClick={() => {
        if (isOpen) {
          setMenuState(false);
        }
      }}
    >
      <MIcon css={iconStyles} />
      <Title>{strings.en.title}</Title>
    </LogoWrap>
  );
};

export default Logo;
