import React from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import * as strings from '../../strings';
import theme from '../../theme';
import { ContentWrapper } from '../Layout';
import { ReactComponent as MIcon } from '~/icons/mr-wreath.svg';

const iconStyles = css`
  width: 30rem;
  display: flex;
  fill: ${theme.color.secondary};

  @media (max-width: 768px) {
    width: 20rem;
  }

  @media (max-width: 411px) {
    width: 15rem;
  }
`;

const Hero = styled.div`
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;

  @media (max-width: 768px) {
    height: 70vh;
  }
`;

const Title = styled.div`
  display: flex;
  font-family: 'Roboto', serif;
  font-weight: 300;
  font-size: 5rem;
  margin: 5px 0 0;
  color: ${theme.color.text.body};

  @media (max-width: 768px) {
    font-size: 2.75rem;
  }

  @media (max-width: 411px) {
    font-size: 2.1rem;
  }
`;

const SubTitle = styled.div`
  display: flex;
  font-family: 'Roboto', serif;
  font-weight: 300;
  font-size: 2rem;
  margin: 5px 0 0;
  color: ${theme.color.secondary};
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 411px) {
    font-size: 1.5rem;
  }
`;

type HomeProps = {} & RouteComponentProps;

const Home: React.FC<HomeProps> = () => {
  return (
    <ContentWrapper>
      <Hero>
        <MIcon css={iconStyles} />
        <Title>{strings.en.title}</Title>
        {/* <SubTitle>{strings.en.subTitle}</SubTitle> */}
      </Hero>
    </ContentWrapper>
  );
};

export default Home;
