import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { RouteComponentProps } from '@reach/router';
import styled from '@emotion/styled';
import theme from '../../theme';
import { ContentWrapper, SubHeader, Paragraph } from '../Layout';
import * as strings from '../../strings';

const SectionWrapper = styled.div`
  margin-top: 0;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 411px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Headshot = styled(Img)`
  display: flex;
  min-width: 20rem;
  border-radius: 6px;
  box-shadow: 3px 3px 20px 0px ${theme.color.gray.medium};
  float: left;
  margin: 0 50px 30px 0;
  max-height: 450px;

  @media (max-width: 768px) {
    min-width: 20rem;
    margin: 0 0 30px 0;
    float: none;
  }

  @media (max-width: 411px) {
    min-width: 15rem;
  }
`;

type AboutProps = {} & RouteComponentProps;

const About: React.FC<AboutProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "headshot" }, extension: { eq: "png" }) {
        childImageSharp {
          fluid(maxWidth: 300, pngQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const title = `${strings.en.about} ${strings.en.firstName}`;

  return (
    <ContentWrapper>
      <SubHeader title={title} />
      <SectionWrapper>
        <Headshot fluid={data.file.childImageSharp.fluid} alt="headshot" />
        {strings.en.aboutText.map(txt => {
          return <Paragraph text={txt} />;
        })}
      </SectionWrapper>
    </ContentWrapper>
  );
};

export default About;
