import React from 'react';
import { Router } from '@reach/router';

import { Layout } from '~/components/Layout';
import { SEO } from '~/components/SEO';
import * as strings from '../strings';
import Home from '../components/Home';
import About from '../components/About';
import Research from '../components/Research';
import Teaching from '../components/Teaching';
import CV from '../components/CV';
import Contact from '../components/Contact';

const Index: React.FC = () => {
  return (
    <Layout>
      <SEO title={strings.en.title} />
      <Router>
        <Home path="/" />
        <About path="/about" />
        <Research path="/research" />
        <Teaching path="/teaching" />
        <CV path="/cv" />
        <Contact path="/contact" />
      </Router>
    </Layout>
  );
};

export default Index;
