import React from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from '@emotion/styled';
import theme from '../../theme';

const Title = styled.h2`
  text-align: left;
  border-bottom: 1px solid ${theme.color.secondary};
  font-weight: 300;
  margin-bottom: 48px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

type AboutProps = {
  title: string;
} & RouteComponentProps;

const SubHeader: React.FC<AboutProps> = props => {
  const { title } = props;

  return <Title>{title}</Title>;
};

export default SubHeader;
