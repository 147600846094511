import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import theme from '../../theme';
import { LoadingSpinner } from '~/components/LoadingIndicators';
import { fadeIn } from '../../utils/styles/animations';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const IFrame = styled.iframe`
  border: none;
  border-radius: 10px;
  background: transparent;
  z-index: 1;
  opacity: 1;
  animation-duration: 0.75s;
  animation-name: ${fadeIn};
  height: 70vh;
  width: 100%;
`;

const loadingStyles = css`
  color: ${theme.color.tertiary};
  margin: auto;
  z-index: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 50px;
`;

type DocViewerProps = {
  source: string;
};

const DocViewer: React.FC<DocViewerProps> = props => {
  const { source } = props;

  return (
    <Wrapper>
      <LoadingSpinner size="50" css={loadingStyles} />
      <IFrame
        // src={source}
        src={`https://docs.google.com/viewer?url=${source}&embedded=true&fitwidth=true`}
        title="Resume"
        allowFullScreen
      />
    </Wrapper>
  );
};

export default DocViewer;
